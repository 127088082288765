import React, { useMemo } from 'react';
import useWindow from '../hooks/useWindow';
import imageMAndSBrand from '../assets/images/partners/m-s-logo.inline.png';
import imageWaitroseBrand from '../assets/images/partners/waitrose-logo.inline.png';
import imageMorrisonsBrand from '../assets/images/partners/morrisons-logo.inline.png';
import imageWAndHSmithBrand from '../assets/images/partners/w-h-smith-logo.inline.png';
import imageCostaCofeeBrand from '../assets/images/partners/costa-coffee-logo.inline.png';
import imageJohnLewisBrand from '../assets/images/partners/john-lewis-logo.inline.png';
import imagePrimarkBrand from '../assets/images/partners/primark-logo.inline.png';
import imageHalfordsBrand from '../assets/images/partners/halfords-logo.inline.png';
import imageUberBrand from '../assets/images/partners/uber-logo.inline.png';
import imageDeliverooBrand from '../assets/images/partners/deliveroo-logo.inline.png';
import imageJustEatBrand from '../assets/images/partners/just-eat-logo.inline.png';
import imageIkeaBrand from '../assets/images/partners/Ikea-logo.inline.png';
import imageShopWithSpriveBrands from '../assets/images/illustrations/shop-with-sprive-brands.png';
import { useCallback } from 'react';

const brandList = [
    {
        image: imageMAndSBrand
    },
    {
        image: imageWaitroseBrand,
        height: 89,
        width: 159
    },
    {
        image: imageMorrisonsBrand,
        height: 77,
        width: 139
    },
    {
        image: imageWAndHSmithBrand,
        height: 38,
        width: 155
    },
    {
        image: imageCostaCofeeBrand,
        height: 79,
        width: 141
    },
    {
        image: imageJohnLewisBrand,
        height: 37,
        width: 159
    },
    {
        image: imagePrimarkBrand,
        height: 21,
        width: 162
    },
    {
        image: imageHalfordsBrand,
        height: 32,
        width: 155
    },
    {
        image: imageUberBrand,
        height: 36,
        width: 105
    },
    {
        image: imageDeliverooBrand,
        height: 82,
        width: 168
    },
    {
        image: imageJustEatBrand,
        height: 92,
        width: 165
    },
    {
        image: imageIkeaBrand,
        height: 58,
        width: 146
    },
]

function BrandsList({ brandsImage, withoutContainer, useCustomItems }) {
  const { isMobile } = useWindow();

  const brandsWrapperClassName = useMemo(() => {
    const basicClassName = 'brands__wrapper';

    return withoutContainer ? `${basicClassName} ${basicClassName}-no-container` : basicClassName;
  }, [withoutContainer]);

  const generateBrandItems = useCallback(() => {
    const components = []

    brandList.forEach((item, index) => {
        components.push((
            <>
                {isMobile ? (
                    <img src={item.image} key={index} />
                ) : (
                    <div className="brands-item" key={index}>
                        <img src={item.image} style={{
                            height: item.height,
                            width: item.width
                        }}/>
                    </div>
                )}
            </>
        ))
    })

    return components
  }, [isMobile])

  return (
    <div className="brands">
      {!isMobile ? (
        <>
          {!useCustomItems ? (
            <p>
              <img
                className="w-100"
                src={brandsImage || imageShopWithSpriveBrands}
                alt="Shop with Sprive Brands"
                loading="lazy"
              />
            </p>
          ) : (
            <div className="brands__container-desktop">
                {generateBrandItems()}
            </div>
          )}
        </>
      ) : (
        <div className={brandsWrapperClassName}>
          <div className="brands__container">
            {generateBrandItems()}
          </div>
        </div>
      )}
    </div>
  );
}

export default BrandsList;
